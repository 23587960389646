import {
  Box,
  BoxProps,
  HStack,
  PinInput,
  PinInputField,
  Flex,
  FormControl,
  FormLabel,
  Button,
  Center,
} from "@chakra-ui/react";
import React from "react";

import { Card } from "~elements/Card";
import { Spinner } from "~elements/Spinner";
import { User, useVerifyPinMutation } from "~generated/graphql";
import { useConfirm } from "~hooks/useConfirm";

type Props = {
  back: () => void;
  email: string;
  onSuccess: (user: User) => void;
};

export const LoginPin: React.VFC<Props & BoxProps> = ({ back, email, onSuccess, ...props }) => {
  const [mutation, { loading }] = useVerifyPinMutation();
  const [failed, setFailed] = React.useState(false);

  const confirm = useConfirm();

  const onComplete = async (pin: string) => {
    setFailed(false);
    const { data } = await mutation({ variables: { input: { email, pin } } });
    const user = data?.verifyPin?.user;

    if (!user) {
      confirm("PINコードが正しくありません", { okOnly: true });
      setFailed(true);
      return;
    }

    onSuccess(user);
  };

  return (
    <Card textAlign="center" w="100%" {...props}>
      <Box textAlign="center">
        <Flex direction={{ md: "row", sm: "column" }} justify="center" mt={4}>
          <Box>
            入力されたメールアドレスに
            <br />
            メールを送信しました
          </Box>
        </Flex>

        <Flex direction={{ md: "row", sm: "column" }} justify="center" mt={4}>
          <Box>
            メールに記載れているURLにアクセスするか
            <br />
            PINコードを入力してください
          </Box>
        </Flex>
      </Box>

      <FormControl mt={8}>
        <FormLabel textAlign="center">PINコード</FormLabel>
        <HStack justify="center">
          <PinInput isDisabled={loading} onComplete={onComplete} size="lg">
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>

        <Center height={70}>{loading && <Spinner size="md" />}</Center>
      </FormControl>

      {failed && (
        <Button onClick={back} variant="outline">
          メールアドレス入力に戻る
        </Button>
      )}
    </Card>
  );
};
