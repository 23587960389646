import {
  chakra,
  Box,
  BoxProps,
  Button,
  FormControl,
  Input,
  InputGroup,
  Image,
  Text,
  Divider,
  FormErrorMessage,
  InputLeftElement,
  Checkbox,
  VStack,
  Flex,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";

import { ChakraLink } from "~/components/alias/ChakraLink";
import { NextLink } from "~/components/alias/NextLink";
import yup from "~/lib/yup";
import { MyFormLabel } from "~compositions/form/MyFormLabel";
import { Card } from "~elements/Card";
import { pagesPath } from "~generated/$path";
import { useSendMagicLinkMutation } from "~generated/graphql";
import { useHandleInvalid } from "~hooks/useHandleInvalid";

type Props = {
  admin?: boolean;
  onSubmit: (email: string) => void;
  title?: string;
};

export type Inputs = {
  acceptance: boolean;
  email: string;
};

const schema = yup.object().shape({
  acceptance: yup.boolean().oneOf([true], "チェックしてください"),
  email: yup.string().email().required(),
});

export const LoginForm: React.VFC<Props & Omit<BoxProps, "onSubmit">> = ({
  admin,
  title = "ログイン・新規登録",
  onSubmit,
  ...props
}) => {
  const methods = useForm<Inputs>({
    defaultValues: {
      acceptance: admin,
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const [sendMagicLinkMutation, { loading }] = useSendMagicLinkMutation();
  const handleInvalid = useHandleInvalid();

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      const url = new URL(location.origin + location.pathname);
      await sendMagicLinkMutation({ variables: { input: { callbackUrl: url.toString(), email } } });
      onSubmit(email);
    } catch (error) {
      // dispatch(setErrorMessage(":systemError"));
    }
  };

  return (
    <Card w="100%" {...props}>
      <Box textAlign="center">
        <Image alt="ROOMiT" mx="auto" src="/logo.svg" width="100px" />
        <Text fontSize="xl" fontWeight="bold" mt={2}>
          {title}
        </Text>
        {!admin && (
          <Box fontSize="sm" mt={2}>
            <chakra.span color="emphasis" fontWeight="bold" mx={1}>
              登録済み
            </chakra.span>
            の方も
            <chakra.span color="emphasis" fontWeight="bold" mx={1}>
              登録がまだ
            </chakra.span>
            の方も
            <br />
            メールアドレスを入力してください
            <Box mt={4}>
              メールに記載されているURLを
              <br />
              クリックするだけで
              <br />
              ログインまたは登録が完了します
            </Box>
          </Box>
        )}
      </Box>

      <Divider my={4} />

      <FormProvider {...methods}>
        <form noValidate onSubmit={methods.handleSubmit(handleSubmit, handleInvalid)}>
          <VStack spacing={5}>
            <FormControl isInvalid={!!methods.formState.errors.email}>
              <MyFormLabel>メールアドレス</MyFormLabel>
              <InputGroup>
                <InputLeftElement>
                  <FontAwesomeIcon icon="envelope" />
                </InputLeftElement>
                <Input inputMode="email" placeholder="メールアドレス" type="email" {...methods.register("email")} />
              </InputGroup>
              <FormErrorMessage>{methods.formState.errors.email?.message}</FormErrorMessage>
            </FormControl>

            {!admin && (
              <FormControl isInvalid={!!methods.formState.errors.acceptance}>
                <Flex alignItems="center">
                  <Checkbox {...methods.register("acceptance")} />
                  <Box ml={2}>
                    <NextLink href={pagesPath.info.terms_of_service.$url()} passHref>
                      <ChakraLink target="_blank" variant="text">
                        利用規約
                      </ChakraLink>
                    </NextLink>
                    ・
                    <NextLink href={pagesPath.info.privacy_policy.$url()} passHref>
                      <ChakraLink target="_blank" variant="text">
                        プライバシーポリシー
                      </ChakraLink>
                    </NextLink>
                    に同意します
                  </Box>
                </Flex>
                <FormErrorMessage>{methods.formState.errors.acceptance?.message}</FormErrorMessage>
              </FormControl>
            )}
            {admin && <input type="hidden" {...methods.register("acceptance")} />}

            <Button colorScheme="brand" isLoading={loading} mt={4} type="submit" w="100%">
              送信
            </Button>
          </VStack>
        </form>
      </FormProvider>
    </Card>
  );
};
