import { UrlObject } from "url";

import { Center, useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { useDispatch } from "react-redux";

import { Spinner } from "~/components/elements/Spinner";
import { setUser } from "~/store/auth";
import { User, useVerifyMagicLinkTokenMutation } from "~generated/graphql";
import { useConfirm } from "~hooks/useConfirm";
import { useSession } from "~hooks/useSession";
import { setAccessToken } from "~lib/auth";
import { LoginForm } from "~standalones/Login/LoginForm";
import { LoginPin } from "~standalones/Login/LoginPin";

export type LoginProps = {
  admin?: boolean;
  prepend?: React.ReactChild;
  redirectBackTo?: string | UrlObject;
  redirectToIfSignedIn?: string | UrlObject;
  title?: string;
};

export const Login: React.VFC<LoginProps> = ({
  admin = false,
  title = "ログイン・新規登録",
  prepend,
  redirectBackTo = "/",
  redirectToIfSignedIn = "/",
}) => {
  const { user } = useSession();
  const dispatch = useDispatch();
  const toast = useToast();
  const confirm = useConfirm();

  const router = useRouter();
  const query = router.query;

  const [mode, setMode] = React.useState<"initializing" | "form" | "pin" | "verifying">("initializing");
  const [email, setEmail] = React.useState<string | undefined>();

  const [verifyMagicLinkTokenMutation] = useVerifyMagicLinkTokenMutation();

  const onSuccess = React.useCallback(
    (user: User) => {
      setAccessToken(user.accessToken);
      dispatch(setUser(user));
      router.push(redirectBackTo);
      toast({ position: "top", status: "success", title: "ログインしました" });
    },
    [dispatch, redirectBackTo, router, toast]
  );

  const signIn = React.useCallback(
    async (magicLinkToken: string) => {
      const result = await verifyMagicLinkTokenMutation({
        variables: { input: { magicLinkToken: String(magicLinkToken) } },
      });
      const user = result.data?.verifyMagicLinkToken?.user;

      if (!user) {
        confirm("ログインできませんでした\nURLが間違っているか、有効期限が切れている可能性があります", {
          okOnly: true,
          title: "エラー",
        });
        setMode("form");
        return;
      }

      onSuccess(user);
    },
    [confirm, onSuccess, verifyMagicLinkTokenMutation]
  );

  React.useEffect(() => {
    if (!router.isReady || mode !== "initializing" || user === undefined) return;

    if (user) {
      router.replace(redirectToIfSignedIn);
      return;
    }

    if (mode === "initializing" && query.magicLinkToken) {
      setMode("verifying");
      signIn(String(query.magicLinkToken));
    } else {
      setMode("form");
    }
  }, [mode, query.magicLinkToken, redirectToIfSignedIn, router, router.isReady, signIn, user]);

  const onLoginFormSubmit = (email: string) => {
    setEmail(email);
    setMode("pin");
    window.scrollTo({ top: 0 });
  };

  return (
    <Center flexDirection="column" h="full" maxW="container.sm" mx="auto" my={8} w="full">
      {prepend}
      {mode === "initializing" && <Spinner centered />}
      {mode === "form" && <LoginForm admin={admin} onSubmit={onLoginFormSubmit} title={title} />}
      {mode === "pin" && email && <LoginPin back={() => setMode("form")} email={email} onSuccess={onSuccess} />}
    </Center>
  );
};
