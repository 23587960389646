import { Badge, FormLabel, FormLabelProps } from "@chakra-ui/react";
import React from "react";

type Props = {
  isEitherRequired?: boolean;
  isOptional?: boolean;
  isRequired?: boolean;
};

export const MyFormLabel: React.VFC<Props & FormLabelProps> = ({
  isEitherRequired,
  isRequired,
  isOptional,
  children,
  ...props
}) => {
  return (
    <FormLabel alignItems="center" display="flex" {...props}>
      {isRequired === true && (
        <Badge colorScheme="brand" fontSize="sm" mr={1} px={2} rounded="xl">
          必須
        </Badge>
      )}
      {(isRequired === false || isOptional === true) && (
        <Badge colorScheme="gray" fontSize="sm" mr={1} px={2} rounded="xl">
          任意
        </Badge>
      )}
      {isEitherRequired === true && (
        <Badge colorScheme="cyan" fontSize="sm" mr={1} px={2} rounded="xl">
          どちらか必須
        </Badge>
      )}
      {children}
    </FormLabel>
  );
};
